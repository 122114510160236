import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './LandingPage.module.css';
import TopbarSearchForm from '../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { createResourceLocatorString } from '../../util/routes';
import { useConfiguration } from '../../context/configurationContext';
import { parse } from '../../util/urlHelpers';
import { useIntl } from '../../util/reactIntl';


const SectionHero = props => {
  if(typeof window === 'undefined') {
    return null;
  }

  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'SectionHero.title',
  });
  const description = intl.formatMessage({
    id: 'SectionHero.description',
  });

  const { config } = props;
  const { keywords, address, origin, bounds } = parse(window.location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const handleSubmit = (values) => {
    const { currentSearchParams } = props;
    const { history, routeConfiguration } = props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };


  const topbarSearcInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
        : null,
    };
  };

  const initialSearchFormValues = topbarSearcInitialValues();

  return (
    <section className={css.heroHolder}>
      <div className={css.backgroundImageWrapper} />
      <div className={css.sectionContent}>
        <header className={css.sectionDetails}>
          <h2 className={css.title}>
            {title}
          </h2>
          <p className={css.description}>
            {description}
          </p>

          <div className={css.searchFormHolder}>
            <TopbarSearchForm
              className={classNames(css.searchLink)}
              desktopInputRoot={css.topbarSearchWithLeftPadding}
              onSubmit={handleSubmit}
              initialValues={initialSearchFormValues}
              appConfig={config}
              heroSearch={true}
            />
          </div>


        </header>
      </div>
    </section>

  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
